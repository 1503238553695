<template>
  <div class="w-100 h-100 py-30 px-10" style="background: rgba(33, 34, 47, 0.5)" v-loading="loading"
    element-loading-background="rgba(0, 0, 0, 0.5)">
    <div class="title_back d-flex ai-center jc-between mx-40">
      <p class="hover" @click="$router.back()">
        <i class="el-icon-arrow-left m-r-5"></i>{{ $t("dragon.back") }}
      </p>
      <!-- 激活 -->
      <!-- <div class="opration text-center cursor" v-if="item.status == 0">
        {{ $t("account.Activate") }}
      </div> -->
      <!-- 已激活 -->
      <!-- <div class="opration text-center hasactive t-color2" v-if="item.status == 1">
        {{ $t("account.hasActivate") }}
      </div> -->
    </div>
    <div class="main d-flex ai-center m-t-20">
      <div class="main_left">
        <img :src="require('@/assets/dragon/' + statusDragon(item.artifactid) + '.png')" width="100%" />
        <!-- <img
          v-if="detailObj.level"
          :src="require('@/assets/dragon/' + status(item.artifactid) + '_b.png')"
          width="100%"
        /> -->
        <h1 class="t-center">
          {{ _i18n.locale == "en" ? item.dragonname_en : _i18n.locale == "tw"?item.dragonname_tw:item.dragonname_in}}
        </h1>
      </div>
      <div class="main_right flex-1 m-l-20">
        <p class="bold fs-xl">{{ $t("dragon.info") }}</p>
        <div class="section m-t-10">
          <div class="border_b d-flex ai-center jc-between p-b-30">
            <div class="d-flex flex-column">
              <p class="t-color2">{{ $t("dragon.name") }}</p>
              <p class="m-t-15">
                {{_i18n.locale == "en" ? item.dragonname_en : _i18n.locale == "tw"?item.dragonname_tw:item.dragonname_in}}
              </p>
            </div>
            <div class="d-flex flex-column">
              <p class="t-color2">{{ $t("dragon.level") }}</p>
              <p class="m-t-15">Lv. {{ detailObj.level }}</p>
            </div>
            <!-- <div class="d-flex flex-column">
              <p class="t-color2">{{ $t("dragon.refined") }}</p>
              <p class="m-t-15">Lv. {{ detailObj.refinelv }}</p>
            </div> -->
            <div class="d-flex flex-column">
              <p class="t-color2">{{ $t("dragon.hp") }}</p>
              <div class="m-t-15 d-flex ai-center">
                <img src="@/assets/dragon/xin.png" alt="" width="30px" />
                <!-- 全队生命 -->
                <span v-if="setInfo.life">{{ setInfo.life[0] }}</span>
              </div>
            </div>
            <div class="d-flex flex-column">
              <p class="t-color2">{{ $t("dragon.atk") }}</p>
              <div class="m-t-15 d-flex ai-center">
                <img src="@/assets/dragon/jian.png" alt="" width="30px" />
                <!-- 全队攻击 -->
                <span v-if="setInfo.life">{{ setInfo.life[1] }}</span>
              </div>
            </div>
          </div>
          <div class="d-flex ai-center m-t-20">
            <div class="logo_left">
              <img class="logo_img" v-if="item.artifactid"
                :src="require('@/assets/dragon/' + statusDragon(item.artifactid) + '/1.png')" width="70px" />
              <div class="lv_bg">
                <p class="fs-sm t-center bold t-color8">
                  {{ detailObj.skilllv }}
                </p>
              </div>
            </div>
            <dir>
              <p class="t-color2 lh-3 m-l-10">{{ tooltip1 }}</p>
              <p class="t-color2 lh-3 m-l-10">{{ tooltip2 }}</p>
            </dir>
          </div>
        </div>
        <!-- 特殊内容 -->
        <p class="bold fs-xl m-t-40">{{ $t("dragon.specialAttributes") }}</p>
        <div class="section m-t-10">
          <div class="d-flex ai-center flex-wrap jc-between">
            <!-- 全队攻击 4级激活 -->
            <div class="d-flex ai-center tesu_item">
              <div class="logo_left">
                <img v-if="item.artifactid" :src="
                    require('@/assets/dragon/' + statusDragon(item.artifactid) + '/2.png')
                  " :class="{ shadow: detailObj.skilllv < 4 }" width="60px" height="60px" />
                <!-- <div class="lv_bg" v-if="detailObj.skilllv >= 4">
                  <p class="fs-sm t-center bold t-color8">
                    {{ detailObj.skilllv }}
                  </p>
                </div> -->
              </div>
              <div class="d-flex flex-column m-l-10">
                <p class="t-color2">
                  <!-- {{ $t("dragon.allatk") }} -->
                  {{ atk }}
                  ({{ $t("dragon.lv4ji") }})
                </p>
                <div class="m-t-15 d-flex ai-center">
                  <img src="@/assets/dragon/jian.png" alt="" width="30px" />
                  <span v-if="detailObj.randomskills">{{ atk }} {{ parseInt((detailObj.randomskills[0])*100) }}%</span>
                </div>
              </div>
            </div>
            <!-- 全队命中 8级激活 -->
            <div class="d-flex ai-center tesu_item">
              <div class="logo_left">
                <img v-if="item.artifactid" :class="{ shadow: detailObj.skilllv < 8 }" :src="
                    require('@/assets/dragon/' + statusDragon(item.artifactid) + '/3.png')
                  " width="60px" height="60px" />
                <!-- <div class="lv_bg" v-if="detailObj.skilllv >= 8">
                  <p class="fs-sm t-center bold t-color8">
                    {{ detailObj.skilllv }}
                  </p>
                </div> -->
              </div>
              <div class="d-flex flex-column m-l-10">
                <p class="t-color2">
                  <!-- {{ $t("dragon.allspd") }} -->
                  {{ acc }}
                  ({{ $t("dragon.lv8ji") }})
                </p>
                <div class="m-t-15 d-flex ai-center">
                  <img src="@/assets/dragon/chibang.png" alt="" width="30px" />
                  <span v-if="detailObj.randomskills">{{ acc }} {{ parseInt((detailObj.randomskills[1])*100) }}%</span>
                </div>
              </div>
            </div>
            <!-- 全队速度 12级激活 -->
            <div class="d-flex ai-center tesu_item">
              <div class="logo_left">
                <img v-if="item.artifactid" :class="{ shadow: detailObj.skilllv < 12 }" :src="
                    require('@/assets/dragon/' + statusDragon(item.artifactid) + '/4.png')
                  " width="60px" height="60px" />
                <!-- <div class="lv_bg" v-if="detailObj.skilllv >= 12">
                  <p class="fs-sm t-center bold t-color8">
                    {{ detailObj.skilllv }}
                  </p>
                </div> -->
              </div>
              <div class="d-flex flex-column m-l-10">
                <p class="t-color2">
                  <!-- {{ $t("dragon.allacc") }} -->
                  {{ spd }}
                  ({{ $t("dragon.lv12ji") }})
                </p>
                <div class="m-t-15 d-flex ai-center">
                  <img src="@/assets/dragon/xin.png" alt="" width="30px" />
                  <span>{{ spd }}</span>
                </div>
              </div>
            </div>
          </div>
        </div>
        <!-- 精炼效果 -->
        <!-- <p class="bold fs-xl m-t-40">{{ $t("dragon.effect") }}</p>
        <div class="section d-flex ai-center jc-center m-t-10">
          <div v-if="setInfo.info2">
            <p>
              {{ $t("dragon.refinelv") }}：{{ setInfo.info2.info.refinelv }}
            </p>
            <p class="my-15">
              {{ $t("dragon.addatk") }}：{{ setInfo.info2.info.addatk }}
            </p>
            <p>
              {{ $t("dragon.totalSkill") }}：{{ setInfo.info2.info.totalSkill }}
            </p>
          </div>
          <i class="el-icon-right mx-50" style="font-size: 40px"></i>
          <div v-if="setInfo.info2">
            <p>
              {{ $t("dragon.refinelv") }}：{{ setInfo.info2.info2.refinelv }}
            </p>
            <p class="my-15">
              {{ $t("dragon.addatk") }}：{{ setInfo.info2.info2.addatk }}
            </p>
            <p>
              {{ $t("dragon.totalSkill") }}：{{
                setInfo.info2.info2.totalSkill
              }}
            </p>
          </div>
        </div> -->
      </div>
    </div>
  </div>
</template>

<script>
import { getDragonsDetail } from "../api/common/index.api";
import textdata from "../common/dataJaon/text_data.json";
import setData from "../common/set";
export default {
  data() {
    return {
      loading: false,
      element: "hot",
      detailObj: {},
      item: {},
      setInfo: {},
      tooltip1: "",
      tooltip2: "",
      atk: "", //攻击
      acc: "", //命中
      spd: "", //速度
    };
  },
  created() {
    this.getDetail();
    this.item = JSON.parse(this.$route.query.item);
  },
  methods: {
    statusDragon(val) {
      //1-火，2-冰，3-风，4-光，5-暗
      switch (val) {
        case 1:
          return "hot";
          break;
        case 2:
          return "ice";
          break;
        case 3:
          return "wind";
          break;
        case 4:
          return "light";
          break;
        case 5:
          return "dark";
          break;
        default:
          break;
      }
    },
    getDetail() {
      this.loading = true;
      getDragonsDetail(
        this.$route.query.id,
        {},
        { Serverid: this.$store.state.serverid }
      ).then((res) => {
        // let data = {
        //   artifactid: 4,
        //   level: 1,
        //   levelrate: 0,
        //   attr: [
        //     {
        //       attrname: "hp_max",
        //       attrvalue: 471,
        //     },
        //   ],
        //   skilllv: 1,
        //   skillbid: 805001,
        //   refinelv: 1,
        //   refineallatk: 1280,
        //   stonenum: 0,
        // };
        // let arrtibute = new setData().updateHallowsBaseInfo(
        //   data,
        //   this.item.status == 0 ? true : false
        // );
        // this.setInfo = arrtibute;
        if (res.data.length != 0) {
          let arrtibute = new setData().updateHallowsBaseInfo(
            res.data,
            this.item.status == 0 ? true : false
          );
          this.setInfo = arrtibute;
          let tooltip1 = textdata.data_text[arrtibute.info1.nameid];
          let tooltip2 = textdata.data_text[arrtibute.info1.skillinfoid];
          this.tooltip1 =
            this._i18n.locale == "en"
              ? tooltip1.s_En +
              res.data.skilllv
              // "【" +
              // this.$t("dragon.refined") +
              // "+" +
              // res.data.refinelv +
              // this.$t("dragon.ji") +
              // "】"
              : this._i18n.locale == "tw" ? tooltip1.s_Tw +
                res.data.skilllv : tooltip1.s_Yn +
                res.data.skilllv
          // "【" +
          // this.$t("dragon.refined") +
          // "+" +
          // res.data.refinelv +
          // this.$t("dragon.ji") +
          // "】";
          // var newtooltip2 = tooltip2.s_Tw.replace("%s", arrtibute.info1.str);
          // console.log(newtooltip2);
          this.tooltip2 =
            this._i18n.locale == "en"
              ? tooltip2.s_En.replace("%s", arrtibute.info1.str)
              : this._i18n.locale == "tw" ? tooltip2.s_Tw.replace("%s", arrtibute.info1.str) : tooltip2.s_Yn.replace("%s", arrtibute.info1.str);
          let atk = textdata.data_text["4" + res.data.skills[0] + "0000001"];
          // console.log("4" + res.data.skills[0] + "0000001");
          // console.log("atk", atk.substring(atk.lastIndexOf("+") + 1, atk.length));
          function str(val) {
            return val.substring(0, val.lastIndexOf("+"))
          }
          this.atk = this._i18n.locale == "en" ? str(atk.s_En) : this._i18n.locale == "tw" ? str(atk.s_Tw) : str(atk.s_Yn);
          let acc = textdata.data_text["4" + res.data.skills[1] + "0000002"];
          this.acc = this._i18n.locale == "en" ? str(acc.s_En) : this._i18n.locale == "tw" ? str(acc.s_Tw) : str(acc.s_Yn);
          let spd = textdata.data_text["4" + res.data.skills[2] + "0000003"];
          this.spd = this._i18n.locale == "en" ? spd.s_En : this._i18n.locale == "tw" ? spd.s_Tw : spd.s_Yn;
        }
        this.detailObj = res.data;
        this.loading = false;
      });
    },
  },
};
</script>

<style lang="scss" scope>
.hasactive {
  width: 180px;
  border: 1px solid #999999;
  background: transparent;
}
.section {
  width: 100%;
  background: rgba(41, 43, 57, 0.5);
  border: 1px solid #4c5158;
  border-radius: 4px;
  padding: 20px 40px;
  .border_b {
    border-bottom: 1px solid #464853;
  }
  .logo_left {
    position: relative;
    .shadow {
      -webkit-filter: grayscale(100%); /* Chrome, Safari, Opera */
      filter: grayscale(100%);
    }
    .lv_bg {
      width: 23px;
      height: 23px;
      line-height: 23px;
      background: url(../assets/card/yuan.png) no-repeat;
      background-size: 100% 100%;
      position: absolute;
      bottom: -5px;
      left: 50%;
      transform: translateX(-50%);
    }
  }
}
.main {
  .main_left {
    width: 35%;
    height: 100%;
    h1 {
      color: #f3e9ca;
      margin-top: 10px;
    }
  }
  .tesu_item {
    margin: 10px 0;
  }
}
@media screen and (max-width: 768px) {
  .title_back {
    margin: 0 10px;
    flex-wrap: wrap;
  }
  .main {
    flex-wrap: wrap;
    .main_left {
      width: 100%;
    }
    .main_right {
      width: 100%;
      margin-left: 0;
      margin-top: 20px;
      .section {
        padding: 10px;
        .title {
          p {
            overflow-x: scroll;
          }
        }
      }
    }
  }
}
</style>